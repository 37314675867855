<template>
  <div class="fragments-container">
    <div class="month-section" v-for="[month, data] in monthlyList" :key="month">
      <div class="month-text" v-if="month > 0">
        {{month}}月
      </div>
      <div class="month-text" v-else>
        之前:&lt;
      </div>
      <fragment v-for="item in data" :key="item.name + item.month" :fragment-data="item"/>
    </div>
  </div>
</template>
<script>
import fragment from '@/components/timeline/fragment.vue';

export default {
  name: 'FRAGMENT_HOLDER',
  components: {
    fragment,
  },
  props: {
    fragments: Array,
  },
  computed: {
    incrementListByMonth() {
      const copy = JSON.parse(JSON.stringify(this.fragments));
      const compare = (a, b) => a.month - b.month;
      return copy.sort(compare);
    },
    monthlyList() {
      const monthlyList = new Map();
      this.incrementListByMonth.forEach((singleFragment) => { // ensure a Map increment by its item's key
        const currentMonth = singleFragment.month;
        if (monthlyList.has(currentMonth)) {
          monthlyList.get(currentMonth).push(singleFragment);
        } else {
          monthlyList.set(currentMonth, [singleFragment]);
        }
      });
      return monthlyList;
    },
  },
};
</script>
<style lang="scss" scoped>
.fragments-container {
  .month-section {
    padding: 20px;
    padding-bottom: 0;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    box-sizing: border-box;
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
    .month-text {
      position: relative;
      font-family: ZCOOL XiaoWei,serif;
      font-size: 24px;
      margin-bottom: 10px;
      color: #d90429;
      // &::after {
      //   content: '';
      //   position: absolute;
      //   bottom: 0;
      //   left: 50%;
      //   transform: translate(-50%, 0);
      //   width: 30px;
      //   height: 1px;
      //   background-color: rgb(205, 87, 82);
      // }
    }
  }
}
</style>
