<template>
  <div class="sns-main-container">
    <router-link :to="{ path: '/timeline/2023' }" class="timeline">
      timeline
    </router-link>
    <div class="single-sns-container" v-for="(sns, index) in snsList" :key="index">
      <a :href="sns.url" class="sns-text">{{ sns.name }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SNS_LIST',
  data() {
    return {
      snsList: [
        // {
        //   value: 'twitter',
        //   name: 'twitter',
        //   url: 'https://twitter.com/H1gh_and_Dry',
        //   iconUrl: '',
        // },
        // {
        //   value: 'bilibili',
        //   name: 'bilibili',
        //   url: 'https://space.bilibili.com/31986715',
        //   iconUrl: '',
        // },
        {
          value: 'mail',
          name: 'mail_to_me',
          url: 'mailto:subdance@126.com',
          iconUrl: '',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.sns-main-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .single-sns-container {
    margin-left: 10px;
    .sns-text {
      text-decoration: none;
      color: rgb(100, 86, 55);
      font-size: 12px;
    }
  }
  .timeline {
    color: rgb(100, 86, 55);
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
}
</style>
