<template>
  <div class="post-body-main-container">
    <div v-if="currentArticle">
      <div class="title-section">
        <div class="article-title-text">
          {{ currentArticle.title }}
        </div>
        <div class="article-intro-text">
          {{ currentArticle.intro }}
        </div>
      </div>
      <line-render v-for="(para, index) in currentArticle.para" :key="index" :line-data="para" />
    </div>
    <div v-else class="empty-post-container">
      no post found
    </div>
  </div>
</template>

<script>
import ARTICLE_DATA from '@/assets/article_data';
import lineRender from '@/components/post/line_render.vue';

export default {
  name: 'POST_BODY',
  components: {
    lineRender,
  },
  data() {
    return {
      articleData: JSON.parse(JSON.stringify(ARTICLE_DATA)),
    };
  },
  computed: {
    currentArticle() {
      const filtered = this.articleData.filter((item) => item.route === this.$route.params.route);
      if (filtered.length) {
        return this.articleData.filter((item) => item.route === this.$route.params.route)[0];
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.post-body-main-container {
  padding: 20px 20px;
  max-width: 450px;
  box-sizing: border-box;

  .title-section {
    margin-bottom: 20px;
    .article-title-text {
      font-size: 30px;
      padding-left: 20px;
      font-family: ZCOOL XiaoWei,serif;
    }
    .article-intro-text {
      text-align: right;
      font-size: 12px;
    }
  }

}
</style>
