<template>
  <div class="blog-post-main-container">
    <div class="column-item blank-space"></div>
    <div class="column-item post-content">
      <post-body />
      <div class="floating-index-menu">
        <index-menu />
      </div>
    </div>
    <div class="column-item index-menu">
      <index-menu />
    </div>
  </div>
</template>

<script>
import postBody from '@/components/post/post_body.vue';
import indexMenu from '@/components/timeline/index_menu.vue';

export default {
  name: 'BLOG_POST',
  components: {
    postBody,
    indexMenu,
  },
  data() {
    return {
      shouldFloatingIndexMenuShow: true,
    };
  },
};
</script>
<style lang="scss" scoped>
.blog-post-main-container {
  display: flex;
  flex-flow: row nowrap;
  min-height: 100vh;
  align-items: stretch;

  .column-item {
    flex: 1 1 0;

    &.index-menu  {
      padding-left: 10px;
    }
    &.post-content {
      display: flex;
      flex-flow: column nowrap;
      padding-bottom: 20px;
      align-items: center;
    }
  }
  .floating-index-menu {
    display: none;
  }
}
@media (max-width: 1000px) {
  .blank-space, .index-menu {
    display: none;
  }
  .floating-index-menu {
    border-top: 1px solid black;
    display: block !important;
    background-color: white;
    max-width: 400px;
    margin: auto;
    margin-top: 80px;
  }
}
</style>
