<template>
  <div class="home-page-main-container">
    <div class="cover-container" @click="toPost">
      <div class="banner-text-container">
        <div class="banner-text">笔记</div>
        <div class="banner-text">乐园</div>
        <div class="banner-text">极东</div>
      </div>
    </div>
    <div class="sns-wrapper">
      <sns-list/>
    </div>
  </div>
</template>

<script>
import snsList from '@/components/sns_list.vue';

export default {
  name: 'HOME_PAGE',
  components: {
    snsList,
  },
  methods: {
    toPost() {
      this.$router.push('/post/kon-eternal');
    },
  },
};
</script>
<style lang="scss" scoped>
.home-page-main-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  .cover-container {
    flex: 0 0 40rem;
    max-width: 90vw;
    height: 600px;
    border: 1px solid red;
    background-image: url('https://konfan.oss-cn-beijing.aliyuncs.com/image/article/tan-di-cyborg-girl07-m.jpeg');
    background-position: center;
    background-size: contain;
    position: relative;
    cursor: pointer;

    .banner-text-container {
      border: 1px solid white;
      position: absolute;
      right: 10px;
      top: 50%;
      display: flex;
      flex-flow: row nowrap;

      .banner-text {
        writing-mode: vertical-rl;
        text-orientation: mixed;
        color: white;
        font-family: 'ZCOOL XiaoWei', serif;
        font-size: 40px;
      }
    }
  }
  .sns-wrapper {
    position: fixed;
    bottom: 0;
    right: 20px;
  }
}
</style>
