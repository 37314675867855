<template>
  <div class="annula-index-main-container">
    <div v-for="(year, index) in annualList" :key="index" @click="changeAnnualIndex(year)" :class="{'annual-lable': true, highlight: year.annualLabel === $route.params.year}">
      {{ year.annualLabel }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'ANNUAL_INDEX',
  props: {
    annualList: Array,
  },
  methods: {
    changeAnnualIndex(year) {
      if (year.annualLabel !== this.$route.params.year) {
        this.$router.push({
          path: `/timeline/${year.annualLabel}`,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.annula-index-main-container {
  display: flex;
  .annual-lable {
    cursor: pointer;
    color: #e5e5e5;
    &.highlight {
      color: #fca311;
      text-decoration: underline;
    }
    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }
}
</style>
