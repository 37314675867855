<template>
  <div class="timelnie-main-container">
    <annual-index :annual-list="timelineData"/>
    <div class="extro-intro">
      {{ currentYearTimelineData.extraInfo }}
    </div>
    <fragments-holder :fragments="currentYearTimelineData.fragments" />
  </div>
</template>
<script>
import TIMELINE_DATA from '@/assets/timeline_data';
import annualIndex from '@/components/timeline/annual_index.vue';
import fragmentsHolder from '../components/timeline/fragments_holder.vue';

export default {
  name: 'TIMELINE',
  components: {
    annualIndex,
    fragmentsHolder,
  },
  data() {
    return {
      timelineData: JSON.parse(JSON.stringify(TIMELINE_DATA)),
    };
  },
  computed: {
    currentYearTimelineData() {
      return this.timelineData.filter((year) => year.annualLabel === this.$route.params.year)?.[0] || {};
    },
  },
};
</script>
<style lang="scss" scoped>
.timelnie-main-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 20px;
}
</style>
