<template>
  <div class="main-empty-route-container">
    <div class="content-holder">
      <img src="https://konfan.oss-cn-beijing.aliyuncs.com/image/article/37.png" class="empty-icon"/>
      <span class="text">You've got the wrong door</span>
      <span class="text">访问了错误的地址...</span>
      <router-link :to="{ path: '/' }" class="router">
        去首页
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EMPTY_ROUTE',
};
</script>
<style lang="scss" scoped>
.content-holder {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
.empty-icon {
  max-width: 400px;
  margin-bottom: 40px;
}
.router {
  margin-top: 40px;
  color: #d90429;
}
.text {
  color: #303133;
  font-size: 14px;
}
</style>
