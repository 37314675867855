const TIMELINE_DATA = [
  // {
  //   annualLabel: 'PAST',
  //   extraInfo: '',
  //   fragments: [
  //     {
  //       name: '異度神劍2',
  //       alias: 'ゼノブレイド2',
  //       author: '',
  //       type: 'game',
  //       comment: '买ns的理由。剧情老套，但是在出色的演出和音乐的加持下一切都显得动人。在夜空下，焰将火花吹向空中的样子让人无法拒绝——我一定带你去乐园！玩xb2的那段时间，白天996，但晚上捧着游戏机就能忘掉一切。',
  //       inProgress: false,
  //       month: 0,
  //       highlight: true,
  //     },
  //     {
  //       name: '生化奇兵：无限',
  //       alias: 'BioShock Infinite',
  //       author: '',
  //       type: 'game',
  //       comment: '剧情里的每一处演出与看似胡言乱语的细节都成了故事的伏笔，拯救世界的同时，却一步步走向自己的毁灭...这是我体验过的剧情最棒的游戏，高中有段时间极为痴迷，很长一段时间QQ头像都是Elizabeth.',
  //       inProgress: false,
  //       month: 0,
  //       highlight: true,
  //     },
  //     {
  //       name: '搏击俱乐部',
  //       alias: 'BioShock Infinite',
  //       author: '',
  //       type: 'game',
  //       comment: '自己看了一遍，推荐给班级同学全班看了一遍，在朋友家和朋友看了一遍。The first rule of fight club is you dont talk about fight club.',
  //       inProgress: false,
  //       month: 0,
  //       highlight: true,
  //     },
  //     {
  //       name: '怪物猎人 世界',
  //       alias: 'Monster Hunter: World',
  //       author: '',
  //       type: 'game',
  //       comment: '很难想象居然有这么底蕴浑厚的动作游戏。和囚二群友共斗煌黑龙、炫辉龙的场面历历在目。',
  //       inProgress: false,
  //       month: 0,
  //       highlight: true,
  //     },
  //     {
  //       name: '神界：原罪2',
  //       alias: 'Divinity: Original Sin II',
  //       author: '',
  //       type: 'game',
  //       comment: '',
  //       inProgress: false,
  //       month: 0,
  //       highlight: false,
  //     },
  //     {
  //       name: '空洞骑士',
  //       alias: 'Hollow Knight',
  //       author: '',
  //       type: 'game',
  //       comment: '地底世界衰败的美感和别出心裁的背景设计让人十分融入，我直呼艺术品。大二暑假全扑在上面了。',
  //       inProgress: false,
  //       month: 0,
  //       highlight: true,
  //     },
  //     {
  //       name: '化物語 上',
  //       alias: 'バケモノガタリ',
  //       author: '西尾維新',
  //       type: 'book',
  //       comment: '',
  //       inProgress: false,
  //       month: 0,
  //       highlight: false,
  //     },
  //     {
  //       name: '化物語 下',
  //       alias: 'バケモノガタリ',
  //       author: '西尾維新',
  //       type: 'book',
  //       comment: '',
  //       inProgress: false,
  //       month: 0,
  //       highlight: false,
  //     },
  //     {
  //       name: '傷物語',
  //       alias: 'キズモノガタリ',
  //       author: '西尾維新',
  //       type: 'book',
  //       comment: '',
  //       inProgress: false,
  //       month: 0,
  //       highlight: false,
  //     },
  //     {
  //       name: '少女不十分',
  //       alias: '少女不十分',
  //       author: '西尾維新',
  //       type: 'book',
  //       comment: '深夜我读到泪流满面，但我却无法形容出骚动我内心的情绪究竟是什么。这本小说让我认定西尾是神一般的小说家。',
  //       inProgress: false,
  //       month: 0,
  //       highlight: true,
  //     },
  //     {
  //       name: '古見同學有社交障礙 1',
  //       alias: '古見さんは、コミュ症です。',
  //       author: '小田智仁',
  //       type: 'book',
  //       comment: '',
  //       inProgress: false,
  //       month: 0,
  //       highlight: false,
  //     },
  //     {
  //       name: '古見同學有社交障礙 2',
  //       alias: '古見さんは、コミュ症です。',
  //       author: '小田智仁',
  //       type: 'book',
  //       comment: '',
  //       inProgress: false,
  //       month: 0,
  //       highlight: false,
  //     },
  //     {
  //       name: '古見同學有社交障礙 3',
  //       alias: '古見さんは、コミュ症です。',
  //       author: '小田智仁',
  //       type: 'book',
  //       comment: '',
  //       inProgress: false,
  //       month: 0,
  //       highlight: false,
  //     },
  //     {
  //       name: '古見同學有社交障礙 4',
  //       alias: '古見さんは、コミュ症です。',
  //       author: '小田智仁',
  //       type: 'book',
  //       comment: '',
  //       inProgress: false,
  //       month: 0,
  //       highlight: false,
  //     },
  //     {
  //       name: '古見同學有社交障礙 5',
  //       alias: '古見さんは、コミュ症です。',
  //       author: '小田智仁',
  //       type: 'book',
  //       comment: '',
  //       inProgress: false,
  //       month: 0,
  //       highlight: false,
  //     },
  //     {
  //       name: '古見同學有社交障礙 6',
  //       alias: '古見さんは、コミュ症です。',
  //       author: '小田智仁',
  //       type: 'book',
  //       comment: '套路用尽，开始无聊。',
  //       inProgress: false,
  //       month: 0,
  //       highlight: false,
  //     },
  //   ],
  // },
  {
    annualLabel: '2022',
    extraInfo: '',
    fragments: [
      {
        name: 'APEX英雄',
        alias: 'APEX LEGENDS',
        author: '',
        type: 'game',
        inProgress: true,
        month: 7,
        highlight: false,
      },
      {
        name: '艾尔登法环',
        alias: 'Elden Ring',
        author: '',
        type: 'game',
        inProgress: false,
        month: 0,
        comment: '很棒的游戏体验。游戏的深度、景观的布局，角色的设计、都堪称完美。剧情的推进绵里藏针，给残破的拉妮带上指环的时候就已经陷入了这个世界。这款游戏火爆到我身边人人都在玩，甚至还和朋友一起连麦玩到深夜。前有绝景！',
        highlight: true,
      },
      {
        name: '安達與島村 1',
        alias: '安達としまむら',
        author: '入間人間',
        type: 'book',
        comment: '安岛小说整个系列我大概读了两三遍。多角度第一人称的切换把每个人都写活了，剧情节奏也恰到好处，气氛温馨情感细腻。让我爱不释手的一套书。社妹的愿望也是我的愿望：让所有世界的安达与岛村都能相遇。',
        inProgress: false,
        month: 0,
        highlight: true,
      },
      {
        name: '安達與島村 2',
        alias: '安達としまむら',
        author: '入間人間',
        type: 'book',
        comment: '',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '安達與島村 3',
        alias: '安達としまむら',
        author: '入間人間',
        type: 'book',
        comment: '',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '安達與島村 4',
        alias: '安達としまむら',
        author: '入間人間',
        type: 'book',
        comment: '',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '安達與島村 5',
        alias: '安達としまむら',
        author: '入間人間',
        type: 'book',
        comment: '',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '安達與島村 6',
        alias: '安達としまむら',
        author: '入間人間',
        type: 'book',
        comment: '',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '安達與島村 7',
        alias: '安達としまむら',
        author: '入間人間',
        type: 'book',
        comment: '',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '安達與島村 8',
        alias: '安達としまむら',
        author: '入間人間',
        type: 'book',
        comment: '',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '安達與島村 9',
        alias: '安達としまむら',
        author: '入間人間',
        type: 'book',
        comment: '',
        inProgress: false,
        month: 0,
        highlight: true,
      },
      {
        name: '異度神劍3',
        alias: 'ゼノブレイド3',
        author: '',
        type: 'game',
        comment: '一切不满在终盘CG过后都消散了。',
        inProgress: false,
        month: 8,
        highlight: true,
      },
      {
        name: '最终幻想VII 重制版',
        alias: 'ファイナルファンタジーVII リメイク',
        author: '',
        type: 'game',
        comment: '',
        inProgress: false,
        month: 9,
        highlight: false,
      },
      {
        name: '花 春之章',
        alias: 'Flowers -Le volume sur printemps-',
        author: '',
        type: 'game',
        comment: '',
        inProgress: false,
        month: 7,
        highlight: false,
      },
      {
        name: '花 夏之章',
        alias: 'Flowers -Le volume sur ete-',
        author: '',
        type: 'game',
        comment: '',
        inProgress: false,
        month: 7,
        highlight: false,
      },
      {
        name: '深海迷航',
        alias: 'Subnautica',
        author: '',
        type: 'game',
        comment: '',
        inProgress: false,
        month: 8,
        highlight: false,
      },
      {
        name: '漫威蜘蛛人 重製版',
        alias: 'Marvel’s Spider-Man Remastered',
        author: '',
        type: 'game',
        comment: '',
        inProgress: false,
        month: 9,
        highlight: false,
      },
      {
        name: '初音未来 Project DIVA MEGA39’s＋',
        alias: "Hatsune Miku: Project DIVA MEGA39's",
        author: '',
        type: 'game',
        comment: '大大大大大大大大，大service💃',
        inProgress: false,
        month: 8,
        highlight: true,
      },
      {
        name: '初音未来 Project DIVA MEGA39’s＋',
        alias: "Hatsune Miku: Project DIVA MEGA39's",
        author: '',
        type: 'game',
        comment: '大大大大大大大大，大service💃',
        inProgress: false,
        month: 9,
        highlight: true,
      },
      {
        name: '近月少女的礼仪',
        alias: '月に寄りそう乙女の作法',
        author: '',
        type: 'game',
        comment: '推完露娜线之后就想一直留在这条线了...',
        inProgress: false,
        month: 7,
        highlight: false,
      },
      {
        name: '美好的每一天～不連續的存在～',
        alias: '素晴らしき日々～不連続存在～',
        author: '',
        type: 'game',
        comment: '剧情让人着迷，角色充满魅力，配乐极为出彩',
        inProgress: false,
        month: 7,
        highlight: true,
      },
      {
        name: '少女妄想中。',
        alias: '少女妄想中。',
        author: '入間人間',
        type: 'book',
        comment: '读完感觉非常温暖，让人能确凿感觉到幸福。叙事手法高超，将前后的伏笔与暗线串起来后，更能体会到这个由超现实、妄想、失去与得到所组成的美妙故事的感动。',
        inProgress: false,
        month: 0,
        highlight: true,
      },
      {
        name: 'End Blue',
        alias: 'エンドブルー',
        author: '入間人間',
        type: 'book',
        comment: '',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '終將成為妳 1',
        alias: 'やがて君になる',
        author: '仲谷鳰',
        type: 'book',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '終將成為妳 2',
        alias: 'やがて君になる',
        author: '仲谷鳰',
        type: 'book',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '終將成為妳 3',
        alias: 'やがて君になる',
        author: '仲谷鳰',
        type: 'book',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '終將成為妳 4',
        alias: 'やがて君になる',
        author: '仲谷鳰',
        type: 'book',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '終將成為妳 5',
        alias: 'やがて君になる',
        author: '仲谷鳰',
        type: 'book',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '終將成為妳 6',
        alias: 'やがて君になる',
        author: '仲谷鳰',
        type: 'book',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '終將成為妳 7',
        alias: 'やがて君になる',
        author: '仲谷鳰',
        type: 'book',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '終將成為妳 8',
        alias: 'やがて君になる',
        author: '仲谷鳰',
        type: 'book',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '終將成為妳 關於佐伯沙彌香 1',
        alias: 'やがて君になる佐伯沙弥香について',
        author: '入間人間',
        type: 'book',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '終將成為妳 關於佐伯沙彌香 2',
        alias: 'やがて君になる佐伯沙弥香について',
        author: '入間人間',
        type: 'book',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '終將成為妳 關於佐伯沙彌香 3',
        alias: 'やがて君になる佐伯沙弥香について',
        author: '入間人間',
        type: 'book',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '說謊的男孩與壞掉的女孩 1',
        alias: '嘘つきみーくんと壊れたまーちゃん',
        author: '入間人間',
        type: 'book',
        inProgress: false,
        month: 0,
        highlight: false,
      },
      {
        name: '创作的基因：我所爱着的MEME们',
        alias: '創作する遺伝子 僕が愛したMEMEたち',
        author: '小島秀夫',
        type: 'book',
        inProgress: false,
        month: 7,
        highlight: false,
      },
      {
        name: '鏈鋸人',
        alias: 'チェンソーマン',
        author: '藤本樹',
        type: 'ebook',
        inProgress: false,
        month: 8,
        highlight: false,
        comment: '很有趣的漫画！但是对我来说仅限于有趣...网络上的hype倒让我觉得有些意外。',
      },
      {
        name: '绯红结系',
        alias: 'SCARLET NEXUS',
        author: '',
        type: 'game',
        inProgress: false,
        month: 9,
        highlight: false,
        comment: '对这种女主没什么抵抗力',
      },
      {
        name: '你和她和她的戀愛。',
        alias: '君と彼女と彼女の恋。',
        author: '',
        type: 'game',
        inProgress: false,
        month: 0,
        comment: '感动、愤怒、反胃、悲伤、痛苦、留恋、满足...推完之后一度处于中毒状态。绝对的爱诞生了绝对的痛苦（物理），但是当曾根美雪泛着泪光站在我面前时却又让人无法拒绝。打破第四面墙是小，真正让我醉心的是双女主鬼斧神工般的刻画。病娇的本质不是得不到就毁掉，而是自私到毁掉你也要得到你。这是让我无法忘怀的galgame，我甚至开始害怕我的择偶观也会受其影响。让人从脊髓颤抖到胃的神作。',
        highlight: true,
      },
      {
        name: '我的小規模自殺',
        alias: '僕の小規模な自殺',
        author: '入間人間',
        type: 'book',
        inProgress: false,
        month: 0,
        highlight: false,
        comment: '让人感到不适，很恶心的小说。入间人间还是懂恶心人。',
      },
      {
        name: 'Little Noah: Scion of Paradise',
        alias: 'リトル ノア　楽園の後継者',
        author: '',
        type: 'game',
        inProgress: false,
        month: 9,
        highlight: false,
        comment: '',
      },
      {
        name: '賽博朋克2077',
        alias: 'Cyberpunk 2077',
        author: '',
        type: 'game',
        inProgress: false,
        month: 8,
        highlight: false,
        comment: '',
      },
      {
        name: '千恋＊万花',
        alias: '千恋＊万花',
        author: '',
        type: 'game',
        inProgress: false,
        month: 0,
        highlight: false,
        comment: '',
      },
      {
        name: '合金裝備V 原爆點',
        alias: 'Metal Gear Solid V: Ground Zeroes',
        author: '',
        type: 'game',
        inProgress: false,
        month: 10,
        highlight: false,
        comment: '',
      },
      {
        name: '合金裝備V 幻痛',
        alias: 'Metal Gear Solid V: The Phantom Pain',
        author: '',
        type: 'game',
        inProgress: false,
        month: 10,
        highlight: true,
        comment: '很难概括的游戏。玩法有趣，动作系统扎实。人设和剧情上既有严肃、哲思的部分，也有通俗、流行的部分。四五年前玩过盗版，如今在steam又通关一遍，七次cloaked in silence后又能听到Quiet哼歌，依然好玩，十一几乎全扑在上面了。游戏的第二章有些草率，有虎头蛇尾之嫌，不过当听完Paz留下的录像带，看着蓝闪蝶在venom snake的义手里消失时，感觉这个游戏更加完整了一点。',
      },
      {
        name: '邪恶冥刻',
        alias: 'Inscryption',
        author: '',
        type: 'game',
        inProgress: false,
        month: 0,
        highlight: false,
        comment: '',
      },
      {
        name: '噬血代碼',
        alias: 'Code Vein',
        author: '',
        type: 'game',
        inProgress: false,
        month: 10,
        highlight: false,
        comment: '',
      },
      {
        name: '斯普拉遁3',
        alias: 'スプラトゥーン3',
        author: '',
        type: 'game',
        inProgress: false,
        month: 10,
        highlight: false,
        comment: '',
      },
      {
        name: 'Cult of the Lamb',
        alias: 'Cult of the Lamb',
        author: '',
        type: 'game',
        inProgress: false,
        month: 10,
        highlight: false,
        comment: '',
      },
      {
        name: '秘境探險4：盜賊末路',
        alias: "Uncharted 4: A Thief's End",
        author: '',
        type: 'game',
        inProgress: false,
        month: 10,
        highlight: false,
        comment: '',
      },
      {
        name: '女神異聞錄5 皇家版',
        alias: 'Persona 5 Royal',
        author: '',
        type: 'game',
        inProgress: false,
        month: 11,
        highlight: true,
        comment: '我的年度游戏',
      },
      {
        name: 'Scorn',
        alias: 'Scorn',
        author: '',
        type: 'game',
        inProgress: false,
        month: 10,
        highlight: false,
        comment: '',
      },
      {
        name: 'Flowers',
        alias: 'Flowers',
        author: '',
        type: 'game',
        inProgress: false,
        month: 11,
        highlight: false,
        comment: '',
      },
      {
        name: 'SIGNALIS',
        alias: 'SIGNALIS',
        author: '',
        type: 'game',
        inProgress: false,
        month: 11,
        highlight: false,
        comment: '',
      },
      {
        name: '暗邪西部',
        alias: 'Evil West',
        author: '',
        type: 'game',
        inProgress: false,
        month: 11,
        highlight: false,
        comment: '',
      },
      {
        name: 'Persona 4 Golden',
        alias: 'Persona 4 Golden',
        author: '',
        type: 'game',
        inProgress: false,
        month: 11,
        highlight: false,
        comment: '',
      },
      {
        name: '罪恶装备 奋战',
        alias: 'Guilty Gear Strive',
        author: '',
        type: 'game',
        inProgress: false,
        month: 12,
        highlight: false,
        comment: 'Ramlethal 很可爱',
      },
      {
        name: '星际战甲',
        alias: 'Warframe',
        author: '',
        type: 'game',
        inProgress: false,
        month: 12,
        highlight: false,
        comment: '',
      },
      {
        name: '決勝時刻®：現代戰爭II 2022',
        alias: 'Call of Duty: Modern Warfare II',
        author: '',
        type: 'game',
        inProgress: false,
        month: 12,
        highlight: false,
        comment: '',
      },
      {
        name: '极品飞车™：不羁',
        alias: 'Need for Speed Unbound',
        author: '',
        type: 'game',
        inProgress: false,
        month: 12,
        highlight: false,
        comment: '',
      },
      {
        name: 'Outer Wilds',
        alias: 'Outer Wilds',
        author: '',
        type: 'game',
        inProgress: false,
        month: 12,
        highlight: false,
        comment: '',
      },
      {
        name: '花 秋之章',
        alias: 'Flowers -Le volume sur automne',
        author: '',
        type: 'game',
        comment: '',
        inProgress: false,
        month: 12,
        highlight: false,
      },
      {
        name: 'Downwell',
        alias: 'Downwell',
        author: '',
        type: 'game',
        comment: '',
        inProgress: false,
        month: 12,
        highlight: false,
      },
      {
        name: '御姐武戏z2',
        alias: 'Onechanbara Z2: Chaos',
        author: '',
        type: 'game',
        comment: '',
        inProgress: false,
        month: 12,
        highlight: false,
      },
    ],
  },
  {
    annualLabel: '2023',
    extraInfo: '',
    fragments: [
      {
        name: '尼尔：自动人形',
        alias: 'NieR:Automata',
        author: '',
        type: 'game',
        inProgress: false,
        month: 1,
        highlight: true,
        comment: '有我喜欢的宅臭恶趣味人设、不拖后腿的战斗系统、调教精致的各种动作。剧情上，对于生命、战争、复仇等宏大主题的处理也都是恰到好处的点到为止，没有假大空的喊口号，只有冷冰冰的真实与充满希望的留白。',
      },
      {
        name: '火焰之紋章 風花雪月',
        alias: 'Fire Emblem: Three Houses',
        author: '',
        type: 'game',
        inProgress: false,
        month: 1,
        highlight: true,
        comment: '',
      },
      {
        name: 'Hi-Fi Rush',
        alias: 'Hi-Fi Rush',
        author: '',
        type: 'game',
        inProgress: false,
        month: 1,
        highlight: false,
        comment: '',
      },
      {
        name: '霍格沃茨之遗',
        alias: 'Hogwarts Legacy',
        author: '',
        type: 'game',
        inProgress: false,
        month: 2,
        highlight: true,
        comment: '',
      },
      {
        name: '死亡空间',
        alias: 'Dead Space',
        author: '',
        type: 'game',
        inProgress: false,
        month: 2,
        highlight: false,
        comment: '',
      },
      {
        name: 'Milk inside a bag of milk inside a bag of milk',
        alias: 'Milk inside a bag of milk inside a bag of milk',
        author: '',
        type: 'game',
        inProgress: false,
        month: 2,
        highlight: false,
        comment: '',
      },
      {
        name: '卧龙：苍天陨落',
        alias: 'Wo Long: Fallen Dynasty',
        author: '',
        type: 'game',
        inProgress: false,
        month: 3,
        highlight: false,
        comment: '',
      },
      {
        name: '神之天平',
        alias: 'ASTLIBRA',
        author: '',
        type: 'game',
        inProgress: false,
        month: 3,
        highlight: true,
        comment: '',
      },
      {
        name: '死亡细胞',
        alias: 'Dead Cells',
        author: '',
        type: 'game',
        inProgress: false,
        month: 3,
        highlight: false,
        comment: '',
      },
      {
        name: '生化危机4 重制版',
        alias: 'Resident Evil 4',
        author: '',
        type: 'game',
        inProgress: false,
        month: 4,
        highlight: true,
        comment: '',
      },
      {
        name: '最后生还者 Part I',
        alias: 'The Last of Us Part I',
        author: '',
        type: 'game',
        inProgress: false,
        month: 4,
        highlight: true,
        comment: '我们需要这种认真做演出，讲故事的游戏',
      },
      {
        name: '勇者斗恶龙XI 寻觅逝去的时光',
        alias: 'ドラゴンクエストXI 過ぎ去りし時を求めて',
        author: '',
        type: 'game',
        inProgress: false,
        month: 5,
        highlight: true,
        comment: '',
      },
      {
        name: '勇者斗恶龙XI 寻觅逝去的时光',
        alias: 'ドラゴンクエストXI 過ぎ去りし時を求めて',
        author: '',
        type: 'game',
        inProgress: false,
        month: 6,
        highlight: true,
        comment: '',
      },
      {
        name: '塞尔达传说 王国之泪',
        alias: 'ゼルダの伝説 ティアーズ オブ ザ キングダム',
        author: '',
        type: 'game',
        inProgress: false,
        month: 5,
        highlight: true,
        comment: '',
      },
      {
        name: '塞尔达传说 王国之泪',
        alias: 'ゼルダの伝説 ティアーズ オブ ザ キングダム',
        author: '',
        type: 'game',
        inProgress: false,
        month: 6,
        highlight: true,
        comment: '',
      },
      {
        name: '《使命召唤®：现代战争II 2022》',
        alias: 'Call of Duty: Modern Warfare II',
        author: '',
        type: 'game',
        inProgress: false,
        month: 2,
        highlight: false,
        comment: '',
      },
      {
        name: '《幽灵诡计》',
        alias: 'ゴースト トリック',
        author: '',
        type: 'game',
        inProgress: false,
        month: 7,
        highlight: true,
        comment: '',
      },
      {
        name: '玛莉的炼金工房 Remake ～萨尔布鲁克的炼金术士～',
        alias: 'マリーのアトリエ ～ザールブルグの炼金术士～',
        author: '',
        type: 'game',
        inProgress: false,
        month: 7,
        highlight: false,
        comment: '',
      },
      {
        name: '蘇菲的鍊金工房～不可思議書的鍊金術士～ DX',
        alias: 'ソフィーのアトリエ 〜不思議な本の錬金術士〜',
        author: '',
        type: 'game',
        inProgress: false,
        month: 7,
        highlight: true,
        comment: '',
      },
      {
        name: '博得之门3',
        alias: "Baldur's Gate 3",
        author: '',
        type: 'game',
        inProgress: false,
        month: 8,
        highlight: true,
        comment: '',
      },
      {
        name: '机战佣兵™VI 境界天火™',
        alias: 'Armored Core VI: Fires of Rubicon',
        author: '',
        type: 'game',
        inProgress: false,
        month: 9,
        highlight: true,
        comment: '',
      },
      {
        name: '新櫻花大戰',
        alias: 'しんサクラたいせん',
        author: '',
        type: 'game',
        inProgress: false,
        month: 4,
        highlight: false,
        comment: '',
      },
      {
        name: '最終幻想XVI',
        alias: 'Final Fantasy XVI',
        author: '',
        type: 'game',
        inProgress: false,
        month: 6,
        highlight: false,
        comment: '',
      },
      {
        name: '重力異想世界',
        alias: 'GRAVITY DAZE',
        author: '',
        type: 'game',
        inProgress: false,
        month: 4,
        highlight: false,
        comment: '',
      },
      {
        name: 'Fate/Samurai Remnant',
        alias: 'Fate/Samurai Remnant',
        author: '',
        type: 'game',
        inProgress: false,
        month: 10,
        highlight: false,
        comment: '',
      },
      {
        name: '蘇菲的鍊金工房2 ～不可思議書的鍊金術士～',
        alias: 'マリーのアトリエ2 ～ザールブルグの炼金术士～',
        author: '',
        type: 'game',
        inProgress: false,
        month: 10,
        highlight: false,
        comment: '',
      },
      {
        name: 'CRYMACHINA',
        alias: '恸哭奇机',
        author: '',
        type: 'game',
        inProgress: false,
        month: 11,
        highlight: false,
        comment: '',
      },
      {
        name: 'Persona 5 Tactica',
        alias: 'Persona 5 Tactica',
        author: '',
        type: 'game',
        inProgress: false,
        month: 12,
        highlight: false,
        comment: '',
      },
      {
        name: 'TEVI',
        alias: 'TEVI',
        author: '',
        type: 'game',
        inProgress: false,
        month: 12,
        highlight: false,
        comment: '',
      },
      {
        name: 'THE FINALS',
        alias: 'THE FINALS',
        author: '',
        type: 'game',
        inProgress: false,
        month: 12,
        highlight: false,
        comment: '',
      },
      {
        name: '魔法使之夜',
        alias: '魔法使いの夜',
        author: '',
        type: 'game',
        inProgress: false,
        month: 12,
        highlight: true,
        comment: '',
      },
      {
        name: '怪物猎人 崛起',
        alias: 'モンスターハンターライズ',
        author: '',
        type: 'game',
        inProgress: false,
        month: 12,
        highlight: true,
        comment: '',
      },
    ],
  },
  {
    annualLabel: '2024',
    extraInfo: '',
    fragments: [
      {
        name: 'Momodora: Moonlit Farewell',
        alias: 'Momodora: Moonlit Farewell',
        author: '',
        type: 'game',
        inProgress: false,
        month: 1,
        highlight: false,
        comment: '',
      },
      {
        name: '终结者莉莉：骑士救赎',
        alias: 'Ender Lilies: Quietus of the Knights',
        author: '',
        type: 'game',
        inProgress: false,
        month: 1,
        highlight: false,
        comment: '',
      },
      {
        name: '梦灯花',
        alias: '梦灯花',
        author: '',
        type: 'game',
        inProgress: false,
        month: 1,
        highlight: false,
        comment: '',
      },
      {
        name: '黄昏天使',
        alias: 'Angel at Dusk',
        author: '',
        type: 'game',
        inProgress: false,
        month: 2,
        highlight: false,
        comment: '',
      },
      {
        name: '碧蓝幻想：Relink',
        alias: 'Granblue Fantasy: Relink',
        author: '',
        type: 'game',
        inProgress: false,
        month: 2,
        highlight: true,
        comment: '',
      },
      {
        name: '圣兽之王',
        alias: 'Unicorn Overlord',
        author: '',
        type: 'game',
        inProgress: false,
        month: 3,
        highlight: true,
        comment: '',
      },
      {
        name: '武士零',
        alias: 'KATANA ZERO',
        author: '',
        type: 'game',
        inProgress: false,
        month: 3,
        highlight: false,
        comment: '',
      },
    ],
  },
];
export default TIMELINE_DATA;
