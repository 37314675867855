<script>
export default {
  name: 'LINE_RENDER',
  props: {
    lineData: String,
  },
  render(c) {
    return c.apply(this, this.richTextRender(this.lineData));
  },
  methods: {
    richTextRender(line) {
      const first = line.charAt(0);
      let renderArguments = [];
      switch (first) {
        case '!': {
          renderArguments = [
            'img',
            {
              class: {
                'article-img': true,
              },
              attrs: {
                src: line.substring(1),
                'data-tag-name': '图片',
              },
              style: {
                transform: `rotate(${2 - Math.random() * 4}deg)`,
              },
              on: {
                mouseover: this.restoreRotate,
              },
            },
          ];
          break;
        }
        case '~': {
          renderArguments = [
            'div',
            {
              class: {
                'article-quote': true,
              },
              attrs: {
                'data-tag-name': '引用段落',
              },
            },
            line.substring(1),
          ];
          break;
        }
        default: {
          renderArguments = [
            'div',
            {
              class: {
                'article-para': true,
              },
              attrs: {
                'data-tag-name': '文本段落',
              },
            },
            line,
          ];
          break;
        }
      }
      return renderArguments;
    },
    restoreRotate(e) {
      e.currentTarget.classList.add('no-rotate-img');
    },
  },
};
</script>
<style lang="scss" scoped>
.article-img {
  display: block;
  width: 80%;
  max-width: 300px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 8px solid rgb(240, 238, 238);
  border-bottom-width: 16px;
  transition: all 0.2s;

  &.no-rotate-img {
    transform: rotate(0deg) !important;
    transition: all 0.2s;
  }
}
.article-para {
  font-size: 14px;
  font-family: "Noto Serif SC",serif;
  color: #303133;
  margin-bottom: 5px;
  &::first-letter {
    margin-left: 15px;
  }
}
.article-quote {
  margin: 10px 0;
  font-size: 14px;
  font-family: "Noto Serif SC",serif;
  color: grey;
  border-left: 2px solid grey;
  padding-left: 10px;
  &::first-letter {
    margin-left: 15px;
  }
}
</style>
