import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '../views/home_page.vue';
import BlogPost from '../views/blog_post.vue';
import TimeLine from '../views/timeline.vue';
import EmptyRoute from '../views/empty_page.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/post/:route',
    name: 'BlogPost',
    component: BlogPost,
  },
  {
    path: '/timeline/:year',
    name: 'TimeLine',
    component: TimeLine,
  },
  {
    path: '/404',
    name: 'EmptyRoute',
    component: EmptyRoute,
  },
];

const router = new VueRouter({
  routes,
});
router.afterEach((to) => {
  if (to.name === null) {
    router.replace({
      name: 'EmptyRoute',
    });
  }
});

export default router;
