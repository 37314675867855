<template>
  <div class="index-menu-main-container">
    <div class="section-banner">
      INDEX
    </div>
    <div v-for="(article, index) in articleData" :key="index">
      <span :class="{'title-text': true, highlight: currentRouteName === article.route}" @click="toArticle(article)">
        {{ article.title }}
      </span>
      <div class="intro-text">
        {{ article.intro }}
      </div>
    </div>
    <div class="to-home-button" @click="toHome">
      <!-- <img src="https://konfan.oss-cn-beijing.aliyuncs.com/image/article/left-arrow.png" class="back-to-home-arrow"/> -->
      <span>close book</span>
    </div>
  </div>
</template>

<script>
import ARTICLE_DATA from '@/assets/article_data';

export default {
  name: 'INDEX_MENU',
  data() {
    return {
      articleData: JSON.parse(JSON.stringify(ARTICLE_DATA)),
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.params.route;
    },
  },
  methods: {
    toArticle(article) {
      if (article.route === this.currentRouteName) return;
      this.$router.push(`/post/${article.route}`);
      window.scrollTo(0, 0);
    },
    toHome() {
      this.$router.push('/');
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.section-banner {
  letter-spacing: 5px;
  margin-bottom: 20px;
  font-weight: bold;
  padding-top: 20px;
}
.title-text {
  font-size: 12px;
  color: #303133;
  cursor: pointer;
  margin-bottom: 30px;
  &:hover {
    text-decoration: underline;
  }
  &.highlight {
    color: rgb(205, 87, 82);
  }
}
.intro-text {
  font-size: 10px;
  color: #737578;
  padding-left: 20px;
  font-style: italic;
}
.to-home-button {
  margin-top: 40px;
  // background-color: #303133;
  // width: 30px;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  color: grey;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: underline;
  font-family: 'Cutive Mono', monospace;

  .back-to-home-arrow {
    width: 15px;
    margin-right: 10px;
  }
}
</style>
