<template>
  <div class="single-fragment-container">
    <div class="wrapper">
      <!-- <div class="left">
        <img :src="fragmentData.poster" class="poster"/>
      </div> -->
      <div class="right">
        <div class="main-info">
          <span class="name">{{ fragmentData.name }}</span>
          <div class="highlight" v-if="fragmentData.highlight">
            <img src="https://konfan.oss-cn-beijing.aliyuncs.com/image/article/3471096A75FF70A45419ACAD59C70733.jpg" class="mark-img"/>
            <span class="highlight-text">真厉害</span>
          </div>
          <span class="cate-text">{{ cateMap[fragmentData.type].name }}</span>
        </div>
        <div class="sub-info">
          <span class="alias-text" v-if="fragmentData.alias">{{ fragmentData.alias }}</span>
          <span class="author-name" v-if="fragmentData.author">by: {{ fragmentData.author }}</span>
        </div>
        <div class="comment-section" v-if="fragmentData.comment">
          <span @click="onClickComment" v-show="!shouldShowComment" class="button-text button-more">MORE</span>
          <span v-show="shouldShowComment" @click="hideComment" class="button-text button-less">HIDE</span>
          <span v-show="shouldShowComment" class="comment-text">{{ fragmentData.comment }}</span>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FRAGMENT',
  props: {
    fragmentData: Object,
  },
  data() {
    return {
      cateMap: {
        game: {
          name: '电子游戏',
        },
        book: {
          name: '书 纸质',
        },
        ebook: {
          name: '书 电子',
        },
        movie: {
          name: '电影',
        },
        anime: {
          name: '动漫',
        },
        music: {
          name: '音乐',
        },
      },
      shouldShowComment: false,
    };
  },
  methods: {
    onClickComment() {
      this.shouldShowComment = true;
    },
    hideComment() {
      this.shouldShowComment = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.single-fragment-container {
  padding-bottom: 6px;
  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(185, 184, 184, 0.143);
    margin-bottom: 8px;
  }
}
.wrapper {
  display: flex;
  flex-flow: row nowrap;
  width: calc(100vw - 40px - 20px);
  max-width: 360px;

  .left {
    position: relative;
    flex: 0 0 100px;
    height: 100px;
  }
  .right {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    font-size: 14px;
    color: #303133;
    .main-info {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      .name {
        color: #2b2d42;
        font-weight: bold;
      }
      .cate-text {
        margin-left: auto;
        font-size: 12px;
        color: #C0C4CC;
      }
      .highlight {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        // padding-right: 20px;
        // border: 1px solid red;
        .mark-img {
          display: block;
          width: 20px;
          height: 20px;
          margin-left: 10px;
          border-radius: 2px;
          position: relative;
        }
        .highlight-text {
          display: block;
          // border: 1px solid red;
          position: absolute;
          word-break: keep-all;
          transform: rotate(90deg) scale(0.5) translate(0, -50%);
          transform-origin: top left;
          left: 35px;
          top: 0px;
          // top: 0;
          // border: 1px solid red;
          // word-break: break-all;
          // width: 20px;
          // text-orientation: upright;
          // writing-mode: vertical-rl;
          // text-orientation: upright;
        }
      }
    }
    .sub-info {
      display: flex;
      flex-flow: row nowrap;
      .alias-text {
        color: #909399;
        margin-right: 5px;
      }
      .author-name {
        color: #909399;
      }
    }
    .comment-section {
      // font-family: ZpixLocal,sans-serif;
      font-size: 12px;
      .button-text {
        color: #d90429;
        margin-right: 10px;
        cursor: pointer;
      }
      .comment-text {
        color: black;
      }
    }
  }
}
</style>
